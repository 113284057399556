<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center md:mt-12 mt-4"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2 "
      >
        <div v-if="controllerView(1) === 1">
          <div class="titre mt-6">Quelle est la valeur du bâtiment ?</div>

          <div class="md:mt-6 mt-4">
            <inputo label="F CFA" :value="valeur" type="number" @info="retourValeur" v-if="deseable !== 1"/>
            <inputo label="F CFA" :value="valeur" type="number" :deseable="true" @info="retourValeur" v-if="deseable === 1"/>
          </div>
        </div>

        <div v-if="controllerView(2) === 1">
          <div class="titre mt-6">Quel est le loyer mensuel ? </div>

          <div class="md:mt-6 mt-4">
            <inputo label="F CFA" :value="loyer" type="number" @info="retourLoyer" v-if="deseable !== 2"/>
            <inputo label="F CFA" :value="loyer" type="number" :deseable="true" @info="retourLoyer" v-if="deseable === 2"/>
          </div>
        </div>

        <div v-if="controllerView(3) === 1">
          <div class="titre mt-6">Quel est la valeur du contenu ? </div>

          <div class="md:mt-6 mt-4">
            <inputo label="F CFA" :value="contenu" type="number" @info="retourContenu"/>
          </div>
        </div>
      </div>
    </div>
    {{ donnee }}
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'

export default {
  name: 'index',
  components: {
    inputo
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      valeur: 0,
      loyer: 0,
      contenu: 0,
      deseable: -1
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {
      // eslint-disable-next-line no-mixed-operators
      if (this.valeur > 0 || this.loyer > 0 && this.contenu > 0) {
        const obj = {
          loyer: this.loyer,
          valeurBatiment: this.valeur,
          valeurContenu: this.contenu
        }
        this.$emit('info', obj)
      }
    }
  },

  created () {
    console.log(this.donnes)
    if (this.donnes.valeur !== undefined) {
      this.loyer = this.donnes.valeur.loyer
      this.valeur = this.donnes.valeur.valeurBatiment
      this.contenu = this.donnes.valeur.valeurContenu
    }
  },

  methods: {
    retourValeur (answer) {
      if (answer !== '') {
        this.valeur = parseInt(answer)
      }
    },

    controllerView (id) {
      if (this.donnes.occupation === 'Propriétaire occupant total' || this.donnes.occupation === 'Propriétaire occupant partiel' || this.donnes.occupation === 'Propriétaire non occupant' || this.donnes.occupation === 'Copropriétaire') {
        const tab = [1, 3]
        if (tab.indexOf(id) !== -1) {
          return 1
        } else {
          return 0
        }
      } else if (this.donnes.occupation === 'Locataire' || this.donnes.occupation === 'Occupation de logement de fonction' || this.donnes.occupation === 'Occupation de logement meublé') {
        const tab = [2, 3]
        if (tab.indexOf(id) !== -1) {
          return 1
        } else {
          return 0
        }
      } else if (this.donnes.occupation === 'Occupation gratuite') {
        const tab = [1, 2, 3]
        if (this.loyer > 0) {
          this.deseable = 1
        } else if (this.valeur > 0) {
          this.deseable = 2
        } else {
          this.deseable = -1
        }
        if (tab.indexOf(id) !== -1) {
          return 1
        } else {
          return 0
        }
      }
    },

    retourLoyer (answer) {
      if (answer !== '') {
        this.loyer = parseInt(answer)
      }
    },
    retourContenu (answer) {
      if (answer !== '') {
        this.contenu = parseInt(answer)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sel{
  width: 99%;
}
.titre{
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  font-family: $font-default;
}
@media screen and (max-width: 700px) {
  .titre{
    font-size: 24px;
    line-height: 32px;
  }
}
</style>
