<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2 "
      >
        <div
          v-for="(item, index) in allInfo"
          :key="index"
          class="w-full mb-3"
        >
          <radio
            :index="item"
            :active="position"
            @radioStat="retourInfo"
            class="w-full"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import radio from '@/components/helper/form/radioAndLabel'

export default {
  name: 'index',
  components: {
    radio
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      allInfo: [
        'Propriétaire occupant total',
        'Propriétaire occupant partiel',
        'Propriétaire non occupant',
        'Locataire',
        'Occupation de logement de fonction',
        'Occupation de logement  meublé',
        'Copropriétaire',
        'Occupation gratuite'
      ],
      position: 'Propriétaire occupant total'
    }
  },

  mounted () {
    if (this.donnes.occupation !== undefined) {
      this.position = this.donnes.occupation
    }
    this.$emit('info', this.position)
  },

  methods: {
    retourInfo (answer) {
      this.position = answer
      this.$emit('info', answer)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sel{
  width: 99%;
}
</style>
