<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center md:mt-12 mt-4"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Ville
        </div>
        <inputo @info="retourVille" :value="ville" placeholder="Ex: Cotonou"/>
      </div>
    </div>

    <div
      class="flex justify-center"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Quartier
        </div>
        <inputo @info="retourQuartier" :value="quartier" placeholder="Ex: Fidjrossè"/>
      </div>
    </div>

    <div
      class="flex justify-center"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Localisation précise
        </div>
        <inputo placeholder="Ex: Derrière la grande mosqué" @info="retourLocalisation" :value="localisation"/>
      </div>
    </div>

    <div class="titre md:mt-6 mt-5">Vous y résider actuellement ?</div>

    <div
      class="flex justify-center mt-5 w-full pl-4 pr-4"
    >
      <div class="button mr-5" :class="{'non': position === 'oui' }" @click="activer('oui')">Oui</div>
      <div class="button" :class="{'non': position === 'non' }" @click="activer('non')">Non</div>
    </div>

    <div
      class="flex justify-center mt-6"
      v-if="position === 'non'"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Entrez votre adresse
        </div>
        <inputo placeholder="Ex: Derrière la grande mosqué" @info="retourActuelAdresse" :value="actuelAdress"/>
      </div>
    </div>
{{ donnee }}
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'

export default {
  name: 'index',
  components: {
    inputo
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      ville: '',
      quartier: '',
      localisation: '',
      position: 'oui',
      actuelAdress: ''
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {
      if (this.ville !== '' && this.quartier !== '' && this.localisation !== '' && this.localisation !== '') {
        const obj = {
          ville: this.ville,
          quartier: this.quartier,
          localisation: this.localisation,
          residentActuel: this.position,
          adresse: this.actuelAdress
        }
        this.$emit('info', obj)
      }
    }
  },

  created () {
    if (this.donnes.adresse !== undefined) {
      this.ville = this.donnes.adresse.ville
      this.quartier = this.donnes.adresse.quartier
      this.localisation = this.donnes.adresse.localisation
      this.actuelAdress = this.donnes.adresse.adresse
      this.position = this.donnes.adresse.residentActuel
    }
  },

  methods: {
    retourVille (answer) {
      this.ville = answer
    },

    retourQuartier (answer) {
      this.quartier = answer
    },

    retourLocalisation (answer) {
      this.localisation = answer
    },

    retourActuelAdresse (answer) {
      this.actuelAdress = answer
    },

    activer (index) {
      this.position = index
      this.$emit('info', index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
}

div{
  font-family: $font-default;
}
.sous-titre{
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #191919;
}

.button{
  background: #E8E8E8;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 20px;
  height: 50px;
  width: 15%;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: black;
}

.non{
  background-color: $base-color;
  color: white;
}

input, .neutre{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 4px;
}
.titre{
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  font-family: $font-default;
}
.button:hover {
  border: 1px solid $base-color;
}
@media screen and (max-width: 700px){
  .sous-titre{
    font-size: 16px;
  }
  .button{
    width: 30%;
    font-size: 17px;
  }
  .input{
    height: 60px;
  }
  input{
    font-size: 16px;
  }
  .titre{
    font-size: 24px;
    line-height: 32px;
  }
}
</style>
