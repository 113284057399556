<template>
  <div class="w-full h-full">
    <div
      class="flex flex-wrap justify-center  mt-12"
    >
      <div v-for="(item, index) in allPlace" :key="index" class="mr-3 ml-2  mb-5">
      <svg-icon
        name="exit"
        class="h-16"
        original
      />
      </div>
    </div>

    <div class="flex items-center justify-center mt-6 md:pt-2">
      <div class="flex justify-center items-center rounded-full md:h-16 md:w-16 w-12 h-12 moins mr-6 cursor-pointer" @click="moins('place')">
        <div class="text-black">-</div>
      </div>

      <div class="inp flex items-center justify-center mr-6">
        <div>{{allPlace.length}}</div>
      </div>

      <div class="flex justify-center items-center rounded-full md:h-16 md:w-16 w-12 h-12 plus cursor-pointer" @click="plus('place')">
        <div class="text-white">+</div>
      </div>
    </div>

    <div class="pt-6">
      <div class="titre md:mt-6">Nombre d’animaux domestiques  ?</div>

      <div
        class="flex flex-wrap justify-center mt-12"
      >
        <div v-for="(item, index) in allDog" :key="index" class="mr-3 ml-2 mb-5">
          <svg-icon
            name="dog"
            class="h-16"
            original
          />
        </div>
      </div>

      <div class="flex items-center justify-center mt-6 pt-2">
        <div class="flex justify-center items-center rounded-full md:h-16 md:w-16 w-12 h-12 moins mr-6 cursor-pointer" @click="moins">
          <div class="text-black">-</div>
        </div>

        <div class="inp flex items-center justify-center mr-6">
          <div>{{allDog.length}}</div>
        </div>

        <div class="flex justify-center items-center rounded-full md:h-16 md:w-16 w-12 h-12 plus cursor-pointer" @click="plus">
          <div class="text-white">+</div>
        </div>
      </div>
    </div>

    <div class="pt-6">
      <div class="titre md:mt-6">Superficie développée (bâtiments)  ?</div>

      <inputo @info="retourSuperficie" type="number" :value="superficie" placeholder="4" label="m2" class="mt-6"/>
    </div>
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'

export default {
  name: 'index',
  components: {
    inputo
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      allPlace: [''],
      allDog: [''],
      superficie: ''
    }
  },

  created () {
    if (this.donnes.bien !== undefined) {
      const tab = []
      const tab2 = []
      let i = 0
      let j = 0
      while (i < this.donnes.bien.piece) {
        tab.push('')
        i++
      }
      while (j < this.donnes.bien.animaux) {
        tab2.push('')
        j++
      }

      this.allPlace = tab
      this.allDog = tab2
      this.superficie = this.donnes.bien.superficie
    }
  },

  methods: {
    moins (index) {
      if (index === 'place') {
        if (this.allPlace.length > 1) {
          this.allPlace.splice(0, 1)
        }
      } else {
        if (this.allDog.length > 0) {
          this.allDog.splice(0, 1)
        }
      }
      this.send()
    },

    plus (index) {
      if (index === 'place') {
        this.allPlace.push('')
      } else {
        this.allDog.push('')
      }
      this.send()
    },

    retourSuperficie (answer) {
      this.superficie = answer
      this.send()
    },

    send () {
      if (this.superficie !== '') {
        const obj = {
          animaux: this.allDog.length,
          piece: this.allPlace.length,
          superficie: this.superficie
        }
        this.$emit('info', obj)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.inp{
  background: #FFFFFF;
  border: 2.4797px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 30.9963px;
  height: 77.49px;
  width: 20%;
  font-weight: normal;
  font-size: 37.1956px;
  line-height: 41px;
  color: #02548B;
}

.titre{
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  font-family: $font-default;
}

div{
  font-family: $font-default;
}
.plus{
  background-color: $base-color;
}
.moins{
  background-color: #E8E8E8;
}
.plus:hover, .moins:hover{
    border: 1px solid $base-color;
}
.rounded-full{
  font-weight: normal;
  font-size: 37.1956px;
  line-height: 41px;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 700px){
  .inp{
    width: 40%;
    height: 59.96px;
    border-radius: 23.9853px;
    font-size: 28px;
  }
  .titre{
    font-size: 24px;
    line-height: 32px;
  }
}
</style>
