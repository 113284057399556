<template>
  <div class="w-full h-full">
    <div class="sous-titre md:w-1/2 w-4/5 text-center mb-3 mr-auto ml-auto mt-5">
      Si oui, vous pourrez inclure les protections financières dans votre protection
    </div>

    <div
      class="flex justify-center mt-5 w-full pl-4 pr-4 pt-4"
    >
      <div class="button mr-5" :class="{'non': position === 'oui' }" @click="activer('oui')">Oui</div>
      <div class="button" :class="{'non': position === 'non' }" @click="activer('non')">Non</div>
    </div>

    <div
      class="flex justify-center mt-6"
      v-if="position === 'oui'"
    >
      <div
        class="md:ml-2 md:mr-4 md:mb-5 w-full md:pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Quelle est  la valeur de votre matériel informatique ?
        </div>
        <inputo type="number" label="F CFA" @info="retourValeur" :value="valeur"/>
      </div>
    </div>

    <div class="titre mt-6">Voulez-vous inclure les garanties financières dans votre protection ?</div>

    <div
      class="flex justify-center mt-5 w-full pl-4 pr-4 pt-4"
    >
      <div class="button mr-5" :class="{'non': positions === 'oui' }" @click="activers('oui')">Oui</div>
      <div class="button" :class="{'non': positions === 'non' }" @click="activers('non')">Non</div>
    </div>

    <div
      class="md:pl-6 w-full md:pr-6"
    >
      <div
        class=" mt-6 bloc p-5"
        v-if="positions === 'oui'"
      >
        <div class="entete">Garanties financières</div>

        <div class="flex mt-4">
          <div class="quiz w-2/3">
            Frais de reconstitution des média
          </div>
          <div class="response w-1/3 text-right">
            + 20 000 Frs
          </div>
        </div>

        <div class="flex mt-2">
          <div class="quiz w-2/3">
            Frais supplémentaires de gestion
          </div>
          <div class="response w-1/3 text-right">
            + 10 000 Frs
          </div>
        </div>
      </div>
    </div>
{{ donnee }}
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'

export default {
  name: 'index',
  components: {
    inputo
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      valeur: 0,
      position: 'oui',
      positions: 'oui'
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {
      if (this.position !== 'oui') {
        if (this.valeur > 0) {
          this.send()
        }
      } else {
        this.send()
      }
    }
  },

  created () {
    if (this.donnes.informatique !== undefined) {
      this.valeur = this.donnes.informatique.valeur
      this.position = this.donnes.informatique.materielInformatique
      this.positions = this.donnes.informatique.garentie
    }
  },

  methods: {
    retourValeur (answer) {
      this.valeur = answer
    },

    activer (index) {
      this.position = index
      this.$emit('info', index)
    },
    activers (index) {
      this.positions = index
    },

    send () {
      const obj = {
        materielInformatique: this.position,
        valeur: this.valeur,
        garentie: this.positions
      }
      this.$emit('info', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
}

div{
  font-family: $font-default;
}
.sous-titre{
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #191919;
}

.button{
  background: #E8E8E8;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 20px;
  height: 50px;
  width: 15%;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: black;
}
.bloc{
  background: #E4E4E4;
  border-radius: 20px;
  .entete{
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #FF4B55;
  }
  .response{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 180%;
    color: #000000;
  }
  .quiz{
    font-weight: normal;
    font-size: 16px;
    line-height: 200%;
    color: #000000;
  }
}
.non{
  background-color: $base-color;
  color: white;
}

input, .neutre{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 4px;
}
.titre{
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  font-family: $font-default;
}
.button:hover {
  border: 1px solid $base-color;
}
@media screen and (max-width: 700px){
  .sous-titre{
    font-size: 16px;
  }
  .button{
    width: 30%;
    font-size: 17px;
  }
  .input{
    height: 60px;
  }
  input{
    font-size: 16px;
  }
  .titre{
    font-size: 24px;
    line-height: 32px;
  }
}
</style>
