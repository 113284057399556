<template>
  <div
    class="radio pl-4 pr-4 flex items-center"
    :class="{'cocher': active === index}"
  >
    <div @click="activer()" class="flex w-full h-full cursor-pointer items-center">
      <svg-icon
        v-if="active === index"
        name="radio"
        class="h-4 mr-4"
        original
      />
      <svg-icon
        v-if="active !== index"
        name="radio-off"
        class="h-4 mr-4"
        original
      />
      <div class="w-full lab">{{ index }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Index',
  components: {
  },
  props: {
    active: {
      type: String,
      default: null
    },
    index: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    activer () {
      this.active = this.index
      this.$emit('radioStat', this.active)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.radio{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  height: 60px;
}

.lab{
  font-weight: normal;
  font-size: 18px;
  color: #606060;
  font-family: 'Mulish', sans-serif;
}
.svg-fill{
  fill: white;
}
.radio:hover{
  border: 1px solid $base-green;
}
.cocher{
  border: 1px solid $base-green;
}

</style>
