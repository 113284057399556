<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2 "
      >
        <div
          v-for="(item, index) in allInfo"
          :key="index"
          class="w-full mb-3"
        >
          <radio
            :index="item"
            :active="position"
            @radioStat="retourInfo"
            class="w-full"
          />
        </div>

        <div class="pt-3">
          <div class="sous-titre mt-6">Adresse</div>
          <inputo placeholder="Ex: 02BP1560" :value="adresse" @info="retourAdresse"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import radio from '@/components/helper/form/radioAndLabel'
import inputo from '@/components/helper/form/input'

export default {
  name: 'index',
  components: {
    radio,
    inputo
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      allInfo: ['Une Villa', 'Un Duplex', 'Un Appartement'],
      position: 'Une Villa',
      adresse: null
    }
  },

  created () {
    if (this.donnes.model !== undefined) {
      this.position = this.donnes.model.model
      this.adresse = this.donnes.model.adresse
    }
  },

  methods: {
    retourInfo (answer) {
      this.position = answer
      if (this.adresse !== null) {
        this.send()
      }
    },

    retourAdresse (answer) {
      if (answer !== '') {
        this.adresse = answer
        this.send()
      }
    },

    send () {
      const obj = {
        adresse: this.adresse,
        model: this.position
      }
      this.$emit('info', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sel{
  width: 99%;
}
.sous-titre {
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #191919;
}
</style>
