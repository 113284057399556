<template>
  <div class="w-full h-full all pt-4">
    <div class="sous-titre w-full mt-4 mb-3">
      Numero de votre pièce d'identité
    </div>
    <inputo @info="retourCard" :value="card" placeholder="23242526"/>

    <div class="sous-titre w-full mt-4 mb-3">
      Quelle est votre statut socio-professionnel?
    </div>
    <multiselect :value="selectedStatus" :option="allStatus" @info="retourStatus"/>

    <div class="sous-titre w-full mt-4">
      Date d’effet du contrat
    </div>
    <datePicker class="mt-3" :deseable="['beforeToday', new Date().toLocaleDateString()]" @info="retourSouscription"/>

    {{donnee}}
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'
import datePicker from '@/components/helper/form/datePicker'
import multiselect from '@/components/helper/form/multiselect'
import status from '@/components/cedro/jsonWord/social-status.json'

export default {
  name: 'index',
  components: {
    inputo,
    datePicker,
    multiselect
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      position: 'oui',
      souscriptionDate: null,
      allStatus: [],
      selectedStatus: 'Autres',
      card: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {
      if (this.card !== null && this.selectedStatus !== 'Autres' && this.souscriptionDate !== null && this.souscriptionDate !== false) {
        const obj = {
          statusSocial: this.selectedStatus,
          cardId: this.card,
          souscriptionDate: this.souscriptionDate
        }
        this.$emit('info', obj)
      }
    }
  },

  created () {
    this.trackPays()
    console.log(this.donnes)
    if (this.donnes.autre !== undefined) {
      this.card = this.donnes.autre.cardId
      this.selectedStatus = this.donnes.autre.statusSocial
      this.souscriptionDate = new Date(this.donnes.autre.souscriptionDate).toLocaleDateString()
    }
  },

  methods: {
    retourCard (answer) {
      this.card = answer
    },

    selected (index) {
      this.position = index
    },

    retourSouscription (answer) {
      this.souscriptionDate = answer
    },

    retourStatus (answer) {
      this.selectedStatus = answer
    },

    retourSearch (answer) {
      this.selectedZone = answer
      this.zone = answer
      this.$emit('info', answer)
    },

    trackPays () {
      for (const item in status) {
        this.allStatus.push(status[item].label)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sous-titre{
  font-family: 'Mulish', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: #000000;
}
.button{
  background: #E8E8E8;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 20px;
  height: 50px;
  width: 15%;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: black;
}
.non{
  background-color: $base-color;
  color: white;
}
.alerte{
  height: 86px;
  border-radius: 20px;
  background-color: #F6E4E5;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: black;
}
.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
}

div{
  font-family: $font-default;
}

@media screen and (max-width: 700px){
  .sous-titre{
    font-size: 16px;
  }
  .button{
    width: 30%;
    font-size: 17px;
  }
}
</style>
